export enum tournamentMatchScheduleTeamStatusFriendlyType {
  Afbudhjem = <any>'afbudhjem',
  Afbudud = <any>'afbudud',
  Udeblivelsehjem = <any>'udeblivelsehjem',
  Udeblivelseud = <any>'udeblivelseud',
  Protesthjem = <any>'protesthjem',
  Protestud = <any>'protestud'
}

export interface SysTournamentRegionId {
  id: null
  region_navn: string
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysTournamentMatchFinalApprovalId {
  id: null
  kampgodkendt_godkendt: boolean
  created_at: string
  updated_at: string
  kampprogram_id: number
}

export interface SysTournamentMemberId {
  id: null
  medlem_status: boolean
  medlem_vejnummer: string
  medlem_foedselsdag: string
  medlem_begyndelse: string
  /* medlem_rolle: number */
  medlem_licens: number
  medlem_sidste_kamp: string
  medlem_paradart: boolean
  medlem_karantaene: boolean
  medlem_karantaene_dato: string
  // medlem_karantaene_periode: string
  medlem_karanteane_slutdato: string
  bypost_id: number
  // bruger_id: number
  user_id: number
  region_id: number
  koen_id: number
  medlem_klubrolle: number
  klubber_id: number
  hold_id: number
  staevne_tilmelding_id: number[]
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysTournamentSaesonId {
  id: null
  saeson_status: boolean
  saeson_start: string
  saeson_slut: string
  saeson_tilmelding: string
  holds_id: number[]
  raekke_id: number[]
  saeson_navn: string
  kampprog_antal_brugte_slots: number
  created_at: string
  updated_at: string
}

export interface SysTournamentCategoryId {
  id: null
  turneringskategori_status: boolean
  turneringskategori_navn: string
  turneringskategori_pris: number
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysTournamentTeamId {
  id: null
  hold_status: boolean
  hold_holdnavn: string
  klubber_id: number
  raekke_id: number
  saeson_id: number
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysTournamentRowId {
  id: null | number
  raekke_status: boolean
  raekke_navn: string
  raekke_vistnavn: string
  raekke_minhold: number
  raekke_maxhold: number
  raekke_antalsaet: number
  turneringskategori_id: number
  raekke_holdprklub: number
  raekke_antalspillere: number
  raekke_maxspillere: number
  region_id: number[]
  parent: number
  child: number[]
  saeson_id: SysTournamentSaesonId
  raekke_oprykning: number
  raekke_gren: number
  published_at: string
  created_at: string
  updated_at: string
}

export default interface SysTournamentRow {
  id: null
  raekke_status: boolean
  raekke_navn: string
  raekke_vistnavn: string
  raekke_minhold: number
  raekke_maxhold: number
  raekke_antalsaet: number
  turneringskategori_id: SysTournamentCategoryId
  raekke_holdprklub: number
  raekke_antalspillere: number
  raekke_maxspillere: number
  region_id: SysTournamentRegionId[]
  parent: SysTournamentRowId
  child: SysTournamentRowId[]
  saeson_id: number
  raekke_oprykning: number
  raekke_gren: number
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysTournamentRounds {
  id: null
  saeson_id: SysTournamentSaesonId
  turneringskategori_id: SysTournamentCategoryId
  turneringsrunder_dato: string
  turneringsrunder_kampe: number
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysTournamentRules {
  id: null
  turneringskategori_id: SysTournamentCategoryId
  regions: SysTournamentRegionId[]
  turneringsregler_point: number
  turneringsregler_resultat: string
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysTournamentMatchScheduleId {
  id: null
  kampprogram_kampnr: number
  kampprogram_dato: string
  kampprogram_nydato: string
  kampprogram_hjemmehold: number
  kampprogram_udehold: number
  kampprogram_kommentar: string
  kampgodkendt_id: SysTournamentMatchFinalApprovalId
  kampprogram_slotindeks: number
  saeson_id: number
  raekke_id: number
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysTournamentMatchSchedule {
  id: null
  kampprogram_kampnr: number
  kampprogram_dato: string
  kampprogram_nydato: string
  kampprogram_hjemmehold: SysTournamentTeamId
  kampprogram_udehold: SysTournamentTeamId
  kampprogram_kommentar: string
  kampprogram_afb_udb: tournamentMatchScheduleTeamStatusFriendlyType
  kampgodkendt_id: SysTournamentMatchFinalApprovalId
  kampprogram_slotindeks: number
  saeson_id: SysTournamentSaesonId
  raekke_id: SysTournamentRowId
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysTournamentMatchFinalApproval {
  id: null
  kampprogram_id: SysTournamentMatchScheduleId
  kampgodkendt_godkendt: boolean
  created_at: string
  updated_at: string
}

export interface SysTournamentMatchSettlementExtId {
  id: null | number
  kampafvikling_spiller_indeks: number
  kampafvikling_spiller_hj: number
  kampafvikling_spiller_ud: number
  kampafvikling_spiller_hj_valgt: boolean
  kampafvikling_spiller_ud_valgt: boolean
  kampafvikling_dom_mod_hjspiller: boolean
  kampafvikling_dom_mod_udspiller: boolean
  created_at: string
  updated_at: string
}

export interface SysTournamentMatchSettlement {
  id: null
  kampprogram_id: SysTournamentMatchScheduleId
  kampafvikling_resultat_hjem: number
  kampafvikling_resultat_ude: number
  kampafvikling_dom_mod_hjhold: boolean
  kampafvikling_dom_mod_udhold: boolean
  kampafvikling_ekstras_id: SysTournamentMatchSettlementExtId[]
  created_at: string
  updated_at: string
}

export interface SysTournamentMatchSettlementId {
  id: null
  kampprogram_id: number
  kampafvikling_resultat_hjem: number
  kampafvikling_resultat_ude: number
  kampafvikling_dom_mod_hjhold: boolean
  kampafvikling_dom_mod_udhold: boolean
  kampafvikling_ekstras_id: number[]
  created_at: string
  updated_at: string
}

export interface SysTournamentMatchSetId {
  id: null | number
  kamp_id: number
  kamp_saet_dart: number
  kamp_saet_lukket: number
  kamp_saet_saet: number
  kamp_saet_hjem_ud: boolean
  kamp_saet_restscore: number
  kamp_saet_udlaeg: null
  created_at: string
  updated_at: string
}

export interface SysTournamentMatch {
  id: null
  kampafvikling_id: SysTournamentMatchSettlementId
  kamp_nummer: number
  kamp_hjemmebane_spiller: string
  kamp_udebane_spiller: string
  kamp_saets_id: SysTournamentMatchSetId[]
}

export interface SysTournamentMatchId {
  id: null
  kampafvikling_id: number
  kamp_nummer: number
  kamp_hjemmebane_spiller: string
  kamp_udebane_spiller: string
}

export interface SysTournamentMatchSet {
  id: null
  kamp_id: SysTournamentMatchId
  kamp_saet_dart: number
  kamp_saet_lukket: number
  kamp_saet_saet: number
  kamp_saet_hjem_ud: boolean
  kamp_saet_restscore: number
  kamp_saet_udlaeg: boolean
}

export interface SysTournamentMatch180 {
  id: null
  kampprogram_id: SysTournamentMatchScheduleId
  medlem_id: SysTournamentMemberId
  kamp_180_antal: number
}

export interface SysTournamentMatchesTeamRankings {
  id: null
  hold_id: SysTournamentTeamId
  stillinger_placering: number
  stillinger_kampe: number
  stillinger_vundet: number
  stillinger_uafgjort: number
  stillinger_tabt: number
  stillinger_score: string
  stillinger_point: number
}
